/* eslint-disable no-undef */
import { getMapsHelper } from "./util/mapsHelper";

export const initMap = (id = "map", mapSource = ".map_source") => {
	const mapHelper = getMapsHelper();

	mapHelper.ready().then(() => {
		const theMap = mapHelper.createMap({
			element: document.getElementById(id),
			locationElementSelector: mapSource,
			useRichmarker: true,
			markerReducer: (el) => {
				return {
					lat: el.getAttribute("lat"),
					lng: el.getAttribute("lng"),
					content: `
						<a class="map_pin" href="${el.getAttribute("link")}" target="${el.getAttribute("target")}">
							<span>${el.getAttribute("count")}</span>
							<img src="/dist/images/icons/map_pin.svg" alt="Visit facility" />
						</a>`,
				};
			},
		});

		google.maps.event.addListenerOnce(theMap, "idle", () => {
			if (document.querySelectorAll(mapSource).length < 2) {
				theMap.setZoom(15);
			}

			document.querySelectorAll(mapSource).forEach((i) => {
				const markerData = mapHelper.getMarker(i);
				markerData.element.addEventListener("mouseenter", () => {
					markerData.element.classList.add("hover");
					i.classList.add("hover");
				});
				markerData.element.addEventListener("mouseleave", () => {
					markerData.element.classList.remove("hover");
					i.classList.remove("hover");
				});
			});
		});
	});
};