export const initHelp = () => {	
	const tipItems = document.querySelectorAll('.tip_heading');
	
	[].forEach.call(tipItems, function(tipItem) {
		tipItem.addEventListener('click', e => {
			const clickedTip = e.currentTarget;
			if (clickedTip.classList.contains('active')) {
				clickedTip.classList.remove('active');
			} else {
				clickedTip.classList.add('active');
			}
		});
	});
	
	function initializeTips(){
		tipItems[0].classList.toggle('active');
	}
	
	setTimeout(function(){
		initializeTips();
	}, 500);
};