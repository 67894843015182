import "./polyfills";
import { contactSubmit } from "./contact.js";
import { initMobileNav, doubleClick } from "./header";
import { initHelp } from "./help";
import { locationsFilter } from "./home.js";
import { initMap } from "./map";
import {
	seeMoreFeatures,
	setActiveFilter,
	setActiveTab,
	initFacilityCaro,
	initFacilityMapToggle,
} from "./facility";
import { initCareersList } from './careers.js';

initMobileNav();
doubleClick();

if(document.querySelector('.careers_list')) {
	initCareersList();
}

if (document.getElementById('accordion')) {
	const acc_items = document.querySelectorAll('.accordion_title');
	const acc_content = document.querySelectorAll('.accordion_content');
	const acc_arrows = document.querySelectorAll('.acc_arrow');

	acc_items.forEach(item => {
		item.addEventListener('click', () => {
			acc_content.forEach(content => {
				content.classList.remove('active');
			});
			acc_arrows.forEach(arrow => {
				arrow.classList.remove('active');
			});
			item.nextElementSibling.classList.add('active');
			item.childNodes[0].classList.add('active');
		});
	});
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if (document.getElementById("help_list")) {
	initHelp();
}
if (document.querySelector("#home_locations_list")) {
	locationsFilter();
}

if (document.querySelector("#map")) {

	/*eslint-disable*/
	let generateMap = false;
	let callback = (entries, observer) => {
		entries.forEach((entry) => {
			if(entry.isIntersecting && !generateMap) {
				initMap();
				generateMap = true;
			}
		});
	};
		
	let options = {
		root: null,
		threshold: 0.1
	};
		
	let observer = new IntersectionObserver(callback, options);
	
	let target = document.getElementById('locations_map');
	observer.observe(target);
	/*eslint-enable*/
}

if (document.querySelector("#see_additional_features")) {
	seeMoreFeatures();
	setActiveTab();
	setActiveFilter();
	initFacilityCaro();
	initMap("facility_map", ".facility_map_source");
	initFacilityMapToggle();
}