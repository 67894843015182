import { AutomatitCarousel } from "./automatit_carousel.m";

export const seeMoreFeatures = () => {
	const see_more_feats = document.getElementById("see_additional_features");
	see_more_feats.addEventListener("click", function () {
		const more_feats = document.getElementById("additional_features");
		const active = more_feats.hasAttribute("active");

		if (active) {
			more_feats.removeAttribute("active");
			more_feats.style.display = "none";
			see_more_feats.innerHTML = "See More Features";
		} else {
			more_feats.setAttribute("active", true);
			more_feats.style.display = "flex";
			see_more_feats.innerHTML = "See Less Features";
		}
	});
};

export const setActiveTab = () => {
	const tabs = document.querySelectorAll(".tab");
	const panels = document.querySelectorAll(".panel");
	for (let i = 0; i < tabs.length; i++) {
		tabs[i].addEventListener("click", function () {
			for (let j = 0; j < tabs.length; j++) {
				tabs[j].removeAttribute("active");
				panels[j].removeAttribute("active");
			}

			const panel_class = tabs[i].getAttribute("panel");
			const panel = document.querySelector(`[id="${panel_class}"]`);

			panel.setAttribute("active", true);
			tabs[i].setAttribute("active", true);
		});
	}
};

export const setActiveFilter = () => {
	const filters = document.querySelectorAll(".filter");
	const checkboxes = document.querySelectorAll(".fake_checkbox");
	const rows = document.querySelectorAll(".unit_row");
	const no_units = document.getElementById("no_units");

	for (let i = 0; i < filters.length; i++) {
		filters[i].addEventListener("click", function () {
			for (let j = 0; j < filters.length; j++) {
				filters[j].removeAttribute("active");
				checkboxes[j].removeAttribute("active");
			}

			const activeFilter = filters[i].getAttribute("size");
			const checkbox = document.querySelector(`.fake_checkbox[size="${activeFilter}"]`);

			let numUnits = 0;

			rows.forEach((row) => {
				row.setAttribute("state", "hide");
				if (row.getAttribute("size") === activeFilter || activeFilter === "all") {
					row.setAttribute("state", "show");
					numUnits++;
				}
			});

			if (numUnits === 0) {
				no_units.style.display = "flex";
			} else {
				no_units.style.display = "none";
			}

			checkbox.setAttribute("active", true);
			filters[i].setAttribute("active", true);
		});
	}
};

export const initFacilityCaro = () => {
	const options = {
		element: document.querySelector("#facility_caro"),
		images: window.facCaroImgs,
		imagesAsBackgrounds: false,
		showDots: false,
		useChevrons: false,
	};

	if (window.facCaroImgs.length === 1) {
		options["intervalTiming"] = false;
	}

	const caro = AutomatitCarousel(options);

	const left = document.getElementById("slide_left");
	left.addEventListener("click", function () {
		caro.slideLeft();
	});

	const right = document.getElementById("slide_right");
	right.addEventListener("click", function () {
		caro.slideRight();
	});
};

export const initFacilityReviewsCaro = () => {
	AutomatitCarousel({
		element: document.querySelector("#reviews_caro"),
		useChevrons: true,
	});
};

export const initFacilityMapToggle = () => {
	const caro_map = document.getElementById("caro_map");
	const caro_gallery = document.getElementById("caro_gallery");
	const facility_caro = document.getElementById("facility_caro");
	const facility_map_wrapper = document.getElementById("facility_map_wrapper");

	caro_map.addEventListener("click", function () {
		facility_caro.style.opacity = 0;
		facility_map_wrapper.style.display = "block";
	});

	caro_gallery.addEventListener("click", function () {
		facility_caro.style.opacity = 100;
		facility_map_wrapper.style.display = "none";
	});
};
