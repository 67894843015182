const careersList = {
	careersList: document.querySelector('.careers_content'),
	careers: document.querySelectorAll('.careers_content .careers_container'),
	careerClick: function(e) {
		let clickedHeading = e.target;
		while(clickedHeading.nodeName !=='H4' || clickedHeading === null) {
			clickedHeading = clickedHeading.parentElement;
		}

		if(clickedHeading) {
			let theCareer = clickedHeading;
			while(theCareer.nodeName !=='LI' || theCareer === null) {
				theCareer = theCareer.parentElement;
			}
			
			if (theCareer.hasAttribute('open')) {
				theCareer.removeAttribute('open');
			} else {
				theCareer.setAttribute('open', '');
			}
		}
	},
	init: function() {
		this.careersList.addEventListener('click', e => this.careerClick(e));
	}
};

export const initCareersList = () => careersList.init();