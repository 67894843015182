export const locationsFilter = () => {
	const locationsFilters = document.querySelectorAll('#home_locations_list .location_filter');
	const locations = document.querySelectorAll('.home_about_location');
	document.getElementById('home_locations_list').addEventListener('click', e => {
		const clickedLocation = e.target.closest('li').getAttribute('location');
		[].forEach.call(locationsFilters, function(locationsFilter) {
			locationsFilter.classList.remove('active');
			if(locationsFilter.getAttribute('location') === clickedLocation){
				locationsFilter.classList.toggle('active');
			}
		});
		[].forEach.call(locations, function(location) {
			location.classList.remove('active');
			if(location.getAttribute('location') === clickedLocation){
				location.classList.toggle('active');
			}
		});
	});
	locationsFilters[0].classList.toggle('active');
	locations[0].classList.toggle('active');
};